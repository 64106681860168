
import { defineComponent, ref } from 'vue';
import GridLine from '@/components/GridLine.vue';

export default defineComponent({
  name: 'Solution',
  components: {
    GridLine,
  },
  setup() {
    const currentTab = ref(0);
    const tabClick = (item: number) => {
      currentTab.value = item;
    };

    return {
      currentTab,
      tabClick,
    };
  },
});
